import React, { Component } from 'react';

class Empty extends Component {
    render() { 
        return (
            <div className="empty">
                <div className="empty-box">
                <svg className="empty-icon" xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512"
                width="120px" height="120px">
		            <path d="M491.797,348.594L266.656,229.656V214.64c0.094-0.023,0.188-0.055,0.297-0.078c35.469-8.781,59.281-30.875,59.281-59.281    c0-32.742-26.547-59.281-59.281-59.281c-30.234,0-56.516,22.125-59.109,51.773c-0.078,0.453-0.125,0.922-0.125,1.391    c0,0.055-0.016,0.109-0.016,0.172c0,5.891,4.781,10.664,10.672,10.664s10.672-4.773,10.672-10.664h0.062    c0.859-9.227,5.344-15.844,9.281-19.969c7.281-7.648,17.703-12.031,28.562-12.031c20.922,0,37.938,17.023,37.938,37.945    c0,7.984-3.328,15.305-9.922,21.758c-7.703,7.539-19.156,13.352-33.141,16.812c0,0-16.203,5-16.484,5.102v3.711h-0.016v26.992    L20.219,348.594C8.141,354.812,0,366.5,0,379.906C0,399.844,18.016,416,40.234,416c0.406,0,1.203,0,1.203,0h429.141    c0,0,0.797,0,1.203,0C494,416,512,399.844,512,379.906C512,366.5,503.875,354.812,491.797,348.594z M471.781,394.656h-1.203    H41.438h-1.203c-10.234,0-18.891-6.75-18.891-14.75c0-4.938,3.234-9.562,8.656-12.375l0.094-0.031l0.094-0.062l225.797-119.289    l225.844,119.289l0.094,0.062l0.094,0.031c5.422,2.812,8.656,7.438,8.656,12.375C490.672,387.906,482.016,394.656,471.781,394.656    z"/>
                    </svg>
                    <h4 className="empty-text">Sorry, no results found.<br></br> Try using other filters.</h4>
                </div>
            </div>
          );
    }
}
 
export default Empty;